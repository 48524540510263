import { Container, Divider, Typography } from "@material-ui/core";
import { BackupOutlined } from "@material-ui/icons";
import { Box, Grid } from "@mui/material";
import axios from "axios";
import {
  AttachmentFilesCard,
  DateTimePicker,
  FormLabel,
  ModalError,
  ModalSuccess,
  Page,
  PrimaryButton,
  SelectInput,
  SkeletonComponent,
  TextInput,
} from "components";
import { ContentState, convertFromHTML, EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { HeaderTitle } from "layouts";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { useDropzone } from "react-dropzone";
import { useHistory, useParams } from "react-router";
import { globalStyles } from "styles";
import {
  arrayBufferToBase64,
  base64ToBlob,
  formatFileSize,
  getErrors,
  stringTruncate,
} from "utils";
import "../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { hardBaseUrl } from "../../../../services/urlConstant";

const CreateOrEditAnnouncementManagement = () => {
  const history = useHistory();
  const classes = globalStyles();
  const { id, typeAnnouncement } = useParams();
  const pageTitle = `${id ? "Edit" : "Create"} Announcement`;
  const oneHourLater = moment().add(1, "hour");
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const [textEditor, setTextEditor] = useState(() => EditorState.createEmpty());
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [valueForm, setValueForm] = useState({
    email_delivery_time: moment(),
    email_recipient: "",
    subject: "",
    files: [],
    imagePreviews: [],
    type: typeAnnouncement,
    imagePreview: "",
    web_start_date: moment(),
    web_end_date: moment().add(1, "hour"),
  });
  const {
    email_delivery_time,
    email_recipient,
    subject,
    files,
    type,
    web_start_date,
    web_end_date,
  } = valueForm;
  const isEmail = type === "email";

  const handleChangeValueForm = (value, key) => {
    setValueForm(currentState => ({
      ...currentState,
      [key]: value,
    }));
  };
  const handleStartDateChange = newStartDate => {
    let newEndDate = web_end_date;
    if (newEndDate && moment(newEndDate).isBefore(moment(newStartDate))) {
      newEndDate = moment(newStartDate).add(1, "minute");
    }
    if (moment(newEndDate).isBefore(oneHourLater)) {
      newEndDate = oneHourLater;
    }
    setValueForm(prevPayload => ({
      ...prevPayload,
      ...(isEmail
        ? {
            web_start_date: newStartDate,
            web_end_date: newEndDate,
          }
        : { email_delivery_time: newStartDate }),
    }));
  };
  const handleEndDateChange = newEndDate => {
    if (moment(newEndDate).isBefore(oneHourLater)) {
      newEndDate = oneHourLater;
    }
    if (
      isEmail
        ? email_delivery_time
        : web_start_date &&
          moment(newEndDate).isSameOrBefore(moment(web_start_date))
    ) {
      newEndDate = moment(web_start_date).add(1, "minute");
    }
    setValueForm(prevPayload => ({
      ...prevPayload,
      web_end_date: newEndDate,
    }));
  };
  const onDrop = useCallback(acceptedFiles => {
    const previews = acceptedFiles.map(file => URL.createObjectURL(file)); // Buat URL preview untuk setiap file
    setValueForm(state => ({
      ...state,
      files: [...state.files, ...acceptedFiles],
      imagePreviews: [...state.imagePreviews, ...previews], // Tambahkan preview URL ke state
    }));
  }, []);

  const handleRemoveFile = selectedIndex => {
    const filteredFiles = files.filter((_, index) => index !== selectedIndex);
    const filteredPreviews = valueForm.imagePreviews.filter(
      (_, index) => index !== selectedIndex
    );

    setValueForm(state => ({
      ...state,
      files: filteredFiles,
      imagePreviews: filteredPreviews, // Perbarui state imagePreviews
    }));
  };

  const uploadCallback = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const dataURL = reader.result;
        const truncatedDataURL = dataURL.substring(10, 30) + "...";
        resolve({ data: { link: dataURL }, link: { url: truncatedDataURL } });
      };
      reader.onerror = error => {
        reject(error);
      };
    });

  const getDetail = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(
        `${hardBaseUrl}/admin/annoucement/detail/${id}`,
        { headers }
      );
      const { data } = res?.data;
      const listFiles = data?.announcement_files?.map(
        async ({ file_path, file_name }) => {
          try {
            const fileResponse = await axios.get(file_path, {
              responseType: "arraybuffer",
            });
            const base64 = await arrayBufferToBase64(fileResponse.data);
            const blob = base64ToBlob(
              base64,
              fileResponse.headers["content-type"]
            );
            // return new File([blob], file_name);
            const file = new File([blob], file_name, {
              lastModified: new Date().getTime(),
              lastModifiedDate: new Date().toString(),
              type: fileResponse.headers["content-type"],
            });
            file.file_path = file_path;
            return file;
          } catch (error) {
            ModalError(error);
          }
        }
      );
      try {
        const values = await Promise.all(listFiles);
        setValueForm(state => ({
          ...state,
          type: data?.type,
          web_start_date: moment(data?.web_start_date),
          web_end_date: moment(data?.web_end_date),
          subject: data?.subject,
          files: values,
          email_recipient: data?.email_recipient,
          email_delivery_time: moment(data?.email_delivery_time),
        }));

        const blocksFromHTML = convertFromHTML(data?.content);
        const contentState = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap
        );
        setTextEditor(EditorState.createWithContent(contentState));
      } catch (error) {
        ModalError(error);
      }
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const saveData = async () => {
    const method = id ? axios.put : axios.post;
    const content = stateToHTML(textEditor.getCurrentContent());
    const formData = new FormData();

    formData.append("type", type);
    formData.append("subject", subject);
    formData.append("content", content);
    if (isEmail) {
      formData.append(
        "email_delivery_time",
        timeZoneFormat(email_delivery_time)
      );
      formData.append("email_recipient", email_recipient);
    } else {
      formData.append("web_start_date", timeZoneFormat(web_start_date));
      formData.append("web_end_date", timeZoneFormat(web_end_date));
    }
    files.forEach(file => formData.append("files", file));

    try {
      setLoadingButton(true);
      await method(
        `${hardBaseUrl}/admin/annoucement/${id ? `edit/${id}` : "create"}`,
        formData,
        { headers }
      );
      ModalSuccess(
        `Successfully ${id ? "Update" : "Create"} Announcement`
      ).then(() => history.goBack());
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingButton(false);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/*",
    multiple: true,
  });
  const configEditor = {
    options: ["inline", "list", "textAlign", "link", "image", "emoji"],
    inline: {
      inDropdown: false,
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
      options: ["bold", "italic", "underline"],
    },
    image: {
      uploadCallback: uploadCallback,
      previewImage: true,
    },
  };

  useEffect(() => {
    if (id) {
      getDetail();
    }
  }, [id]);

  const dropzoneStyle = useMemo(() => {
    return isDragActive ? classes?.dragActive : "";
  }, [isDragActive, classes?.activeStyle]);

  return (
    <Page className={classes.root} title={pageTitle}>
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle
            title={pageTitle}
            breadcrumbData={breadcrumbData(pageTitle)}
            backButton
          />
          <Divider className={classes.divider} />
          <FormLabel label="Select Announcement Management Type" />
          <SelectInput
            options={optionsType}
            optionKey="type"
            optionLabel="title"
            value={type || ""}
            onChange={e => handleChangeValueForm(e?.target?.value, "type")}
            width={300}
          />
          <Box border="1px solid #ebebeb" borderRadius="5px" p="24px" my="16px">
            <Grid container columnSpacing={2} rowSpacing={1}>
              <Grid item xs={6}>
                <FormLabel
                  label={isEmail ? "Email Delivery Time" : "Start Date"}
                />
                <DateTimePicker
                  value={isEmail ? email_delivery_time : web_start_date}
                  onChange={handleStartDateChange}
                  minDate={moment()}
                  maxDate={moment().add(4, "years")}
                />
              </Grid>
              <Grid item xs={6}>
                <FormLabel label={isEmail ? "Recipient" : "End Date"} />
                {isEmail ? (
                  <SelectInput
                    options={optionsRecipient}
                    optionKey="id"
                    optionLabel="title"
                    value={email_recipient || "all"}
                    onChange={e =>
                      handleChangeValueForm(e?.target?.value, "email_recipient")
                    }
                    width="100%"
                  />
                ) : (
                  <DateTimePicker
                    value={web_end_date}
                    onChange={handleEndDateChange}
                    minDate={web_start_date}
                    maxDate={moment().add(4, "years")}
                    defaultValue={moment()}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <FormLabel label="Subject" />
                <TextInput
                  placeholder="Subject"
                  value={subject}
                  onChange={e =>
                    handleChangeValueForm(e?.target?.value, "subject")
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <FormLabel label="Upload File / Image" />
                <div
                  {...getRootProps({
                    className: `${classes?.dragFilesContainer} ${dropzoneStyle}`,
                  })}
                >
                  <input {...getInputProps()} />
                  <Box color="grey">
                    <BackupOutlined className={classes?.uploadIcon} />
                  </Box>
                  <Typography>
                    {isDragActive
                      ? "Drop the files here..."
                      : "Drag files or click to upload"}
                  </Typography>
                </div>

                {/* Tampilkan preview gambar jika ada file yang diunggah */}
                {valueForm.imagePreviews.length > 0 && (
                  <Box mt={2} display="flex" flexWrap="wrap" gap={2}>
                    {valueForm.imagePreviews.map((preview, index) => (
                      <Box
                        key={index}
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                      >
                        <img
                          src={preview}
                          alt={`preview-${index}`}
                          style={{
                            width: "150px",
                            height: "150px",
                            objectFit: "cover",
                          }}
                        />
                      </Box>
                    ))}
                  </Box>
                )}

                {/* Daftar File yang Diunggah */}
                {files.length > 0 && (
                  <Grid container direction="column" rowSpacing={1} mt={1}>
                    {files?.map(({ file_path, name, size }, index) => (
                      <Grid item key={index}>
                        <AttachmentFilesCard
                          fileName={stringTruncate(name, 50)}
                          fileSize={formatFileSize(size)}
                          handleRemove={() => handleRemoveFile(index)}
                          filePath={file_path}
                          tooltipRemove="Remove File"
                          tooltipPreview="View File"
                        />
                      </Grid>
                    ))}
                  </Grid>
                )}
              </Grid>

              <Grid item xs={12}>
                <FormLabel label="Announcement Content" />
                <Editor
                  toolbar={configEditor}
                  editorState={textEditor}
                  wrapperClassName="wrapper-class"
                  editorClassName="editor-class input_description"
                  editorStyle={{
                    height: "60vH",
                    backgroundColor: "white",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                  }}
                  toolbarClassName="toolbar-class"
                  wrapperStyle={{
                    border: "1px solid #ccc",
                    paddingLeft: 0,
                    paddingRight: 0,
                  }}
                  onEditorStateChange={setTextEditor}
                />
              </Grid>
            </Grid>
          </Box>
          <Grid container justifyContent="right">
            <PrimaryButton
              label={id ? "Save" : "Create"}
              onClick={saveData}
              loading={loadingButton}
              disabled={loadingButton}
            />
          </Grid>
        </Container>
      )}
    </Page>
  );
};

const timeZoneFormat = date => moment(date).format("YYYY-MM-DDTHH:mm:ssZ");
const breadcrumbData = pageTitle => [
  {
    label: "Configuration",
    link: "/admin/konfigurasi/announcement-management",
  },
  {
    label: "Announcement Management",
    link: "/admin/konfigurasi/announcement-management",
  },
  {
    label: pageTitle,
    active: true,
  },
];
const optionsType = [
  {
    type: "web",
    title: "Website Announcement Content",
  },
  {
    type: "email",
    title: "Email Announcement",
  },
];
const optionsRecipient = [
  {
    id: "all",
    title: "All",
  },
  {
    id: "publisher",
    title: "Publisher",
  },
  {
    id: "composer",
    title: "Composer",
  },
  {
    id: "association",
    title: "Association",
  },
];

export default CreateOrEditAnnouncementManagement;
