import {
  Breadcrumbs,
  Button,
  Container,
  Divider,
  Grid,
  Box,
  IconButton,
  Tooltip,
  Typography,
  createTheme,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { ThemeProvider, makeStyles } from "@material-ui/styles";
import axios from "axios";
import { InnoTable } from "inno-ui";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import updateIcon from "../../../../assets/img/pencilVector.svg";
import {
  ButtonWithModalPerItem,
  PrimaryButton,
} from "../../../../components/atoms/Button";
import { ButtonGroupTop } from "../../../../components/atoms/Button";
import { Page, SkeletonComponent } from "components";
import { hardBaseUrl } from "../../../../services/urlConstant";
import { emptyText } from "../../../../utils";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

function Pembayaran() {
  const history = useHistory();
  const [response, setResponse] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalData, setTotalData] = useState(1);
  const [page, setPage] = useState(1);
  const [search] = useState("");
  const [getColor, setGetColor] = useState("");
  const [loadingTable, setLoadingTable] = useState(false);
  const pagesCount = Math.ceil(totalData / rowsPerPage);

  const handleChangePage = (event, data) => {
    setPage(data);
  };

  const getResponse = () => {
    setLoadingTable(true);
    const theToken = localStorage.getItem("token");
    const url = `${hardBaseUrl}/currency/datatable?page=${page}&per_page=${rowsPerPage}&search=${search}`;
    axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${theToken}`,
        },
      })
      .then(res => {
        setResponse(res.data.data);
        setPage(res.data.meta.page);
        setTotalData(res.data.meta.found);
        setLoadingTable(false);
      })
      .catch(err => {
        new Error(err);
        setLoadingTable(false);
      });
  };

  const handleChangeRowsPerPage = event => {
    event.preventDefault();
    setRowsPerPage(event.target.value);

    setPage(1);
  };

  const handleDelete = id => {
    const url = `${hardBaseUrl}/currency/delete/${id}`;
    let theToken = localStorage.getItem("token");
    axios
      .delete(url, {
        headers: {
          Authorization: `Bearer ${theToken}`,
        },
      })
      .then(res => {
        if (res.data.message === "success") {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Data has been deleted",
          }).then(result => {
            if (result.isConfirmed === true) {
              getResponse();
            }
          });
        }
      })
      .catch(err => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err.response.data.errors[0].message,
        });
      });
  };

  const getMainDsp = async () => {
    try {
      const token = localStorage.getItem("token");
      const url = `${hardBaseUrl}/me`;
      const res = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setGetColor(res.data.data.publisher.theme_color);
    } catch (e) {
      new Error(e);
    }
  };

  useEffect(() => {
    getMainDsp();
    getResponse();
  }, [page, rowsPerPage, search]);

  const handleView = item => {
    const pathname = `/admin/parameter/admin-currency/edit/${item.id}`;
    const state = { customer: item };
    history.push({ pathname, state });
  };

  const useStyles = makeStyles(theme => ({
    root: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    divider: {
      margin: theme.spacing(2, 0),
      borderTop: "1px solid #e1e1e1",
    },
    results: {
      marginTop: theme.spacing(3),
    },
    paper: {
      position: "absolute",
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    textAdd: {
      color: "white",
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    button: {
      padding: "10px 28px 10px 28px",
      backgroundColor: "black",
      color: "white",
      width: "213px",
      height: "40px",
      borderRadius: "6px",
      textTransform: "none",
      whiteSpace: "nowrap",
      "&:hover": {
        backgroundColor: "black",
        color: "white",
      },
    },
    subtitle: {
      fontSize: "18px",
      fontWeight: "bold",
      lineHeight: "28px",
    },
    breadcrumbs: {
      fontSize: "14px",
      fontWeight: 400,
      fontStyle: "regular",
      color: "#111827",
    },
    breadCrumbsActive: {
      fontSize: "14px",
      fontWeight: 400,
      fontStyle: "regular",
      color: "#687083",
    },
    pagination: {
      "& .Mui-selected": {
        backgroundColor: "black",
        color: "white",
      },
    },
    pageSize: {
      height: "30px",
      borderRadius: "6px",
      border: "1px solid #D1D5DC",
      paddingLeft: "5px",
      paddingRight: "5px",
      marginTop: "5px",
    },
    paginationText: {
      fontSize: "14px",
      fontWeight: "normal",
      fontStyle: "normal",
    },
    btnEdit: {
      backgroundColor: "black",
      color: "white",
      width: "30px",
      height: "30px",
      padding: "6px",
      borderRadius: "6px",
      "&:hover, &.MuiIconButton": {
        background: "#374151 !important",
      },
    },
    btnDelete: {
      backgroundColor: "#111827",
      color: "white",
      width: "35px",
      height: "35px",
      padding: "6px",
      borderRadius: "6px",
      marginLeft: "10px",
      cursor: "pointer",
      "&:hover, &.MuiIconButton": {
        background: "#374151 !important",
      },
    },
    font24: {
      fontSize: "24px",
      fontWeight: "700",
      lineHeight: "32px",
    },
    imgIcon: {
      filter: " brightness(0) invert(1)",
    },
    textStyle: {
      marginTop: "5px",
      color: "#8f8f8f",
      fontSize: "14px",
    },
  }));
  const classes = useStyles();

  const customAction = item => {
    return (
      <Box display="flex">
        <Tooltip title={"Update"} placement="bottom">
          <IconButton
            className={classes.btnEdit}
            onClick={() => handleView(item)}
          >
            <img
              src={updateIcon}
              alt="update-icon"
              className={classes.imgIcon}
            />
          </IconButton>
        </Tooltip>
        <Tooltip title={"Delete"} placement="bottom">
          <ButtonWithModalPerItem
            item={item}
            title={"Remove Currency"}
            dialogTitle={"Remove Currency"}
            subTitle={"Are you sure you want to remove this currency?"}
            handleDelete={() => {
              handleDelete(item.id);
            }}
          />
        </Tooltip>
      </Box>
    );
  };

  const truncate = (str, n) => {
    return str?.length > n ? str.substr(0, n - 1) + "..." : str || "-";
  };

  const columnTable = [
    {
      name: "all",
      title: "Action",
      renderText: item => customAction(item),
    },
    {
      name: "all",
      title: "ISO Code",
      renderText: item => emptyText(item?.currency_iso_code),
    },
    {
      name: "all",
      title: "Name",
      renderText: item => emptyText(item?.currency_name),
    },
    {
      name: "all",
      title: "Symbol",
      renderText: item => emptyText(item?.currency_symbol_code),
    },
    {
      name: "all",
      title: "Description",
      renderText: item => truncate(item?.description, 50),
    },
  ];

  return (
    <Page className={classes.root} title="Currency">
      {loadingTable ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <Grid
            alignItems="flex-end"
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <ThemeProvider theme={theme}>
                <Typography className={classes.font24}>Master</Typography>
              </ThemeProvider>
            </Grid>
            <Grid item>
              <Breadcrumbs aria-label="breadcrumb">
                <Typography className={classes.breadCrumbs}>Home</Typography>

                <Typography className={classes.breadCrumbs}>
                  Parameter
                </Typography>

                <Typography className={classes.breadCrumbsActive}>
                  Currency
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <ButtonGroupTop />
          <Divider className={classes.divider} />
          <ThemeProvider theme={theme}>
            <Typography variant="h3" className={classes.subtitle}>
              Currency
            </Typography>
          </ThemeProvider>
          <ThemeProvider theme={theme}>
            <p className={classes.textStyle}>
              Feature to add currency information{" "}
            </p>
          </ThemeProvider>
          <Box margin="15px 0px" display="flex" justifyContent="flex-end">
            <PrimaryButton
              label="Add Currency"
              onClick={() =>
                history.push("/admin/parameter/admin-currency/tambah")
              }
            />
          </Box>
          <Box marginTop="10px" marginBottom="10px">
            {response && (
              <InnoTable
                columns={columnTable}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                idColumnName={"performer_id"}
                isLoading={loadingTable}
                isUsingCheckbox={false}
                items={response}
                loadingColor={""}
                page={page}
                rowsPerPage={rowsPerPage}
                totalPage={pagesCount}
              />
            )}
          </Box>
        </Container>
      )}
    </Page>
  );
}

export default Pembayaran;
