import React, { useState, useRef, useEffect } from "react";
import { Page, PrimaryButton } from "components";
import { makeStyles } from "@material-ui/styles";
import { Autocomplete } from "@material-ui/lab";
import {
  Breadcrumbs,
  Container,
  Grid,
  Divider,
  TextField,
  InputAdornment,
  Button,
  Typography,
  FormControl,
  IconButton,
  Box,
  Checkbox,
  Backdrop,
  FormGroup,
  FormControlLabel,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import deleteIcon from "assets/img/trashVector.svg";
import PublishIcon from "@material-ui/icons/Publish";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CircularProgress from "@material-ui/core/CircularProgress";
import { PhotoshopPicker } from "react-color";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { useParams } from "react-router-dom";
import axios from "axios";
import { hardBaseUrl } from "../../../../services/urlConstant";
import Swal from "sweetalert2";


const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  colorPickerArea: {
    marginTop: theme.spacing(2),
  },
  button: {
    textTransform: "none",
    marginTop: "16px",
    backgroundColor: "black",
    color: "white",
    width: "135px",
    height: "40px",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  imgPreview: {
    maxHeight: "120px",
    maxWidth: "300px",
  },
  fileUploadArea: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  inputFields: {
    height: "44px",
    paddingTop: "0px",
    paddingBottom: "0px",
  },
  inputFieldsUrl: {
    height: "44px",
    paddingTop: "0px",
    paddingBottom: "0px",
    borderRight: "1px solid #D1D5DC",
  },
  inputFieldsTlp: {
    height: "44px",
    paddingTop: "0px",
    paddingBottom: "0px",
    borderLeft: "1px solid #D1D5DC",
    paddingLeft: "14px",
  },
  myTextField: {
    height: "44px",
    paddingBottom: "0px",
    paddingTop: "0px",
  },
  rootSwitch: {
    width: "40px",
    height: "20px",
    padding: "0px",
    borderRadius: "10px",
    marginRight: "8px",
    position: "relative",
    backgroundColor: "green",
    opacity: 1,
  },
  required: {
    color: "red",
    marginLeft: 5,
  },
  formRequired: {
    fontSize: "14px",
    marginTop: "16px",
  },
  "&.MuiSwitch-colorSecondary.Mui-checked": {
    backgroundColor: "green",
  },
  switchBase: {
    padding: "10px",
    color: "white",
    "&.Mui-checked + $track": {
      border: "none",
      color: "white",
      backgroundColor: "black",
      opacity: 1,
    },
    // ini ketika di checked
    "&.MuiSwitch-colorSecondary.Mui-checked + $track": {
      border: "none",
      color: "white",
      backgroundColor: "black",
      opacity: 1,
    },
  },
  buttonSetting: {
    backgroundColor: "black",
    color: "white",
    width: "130px",
    marginBottom: 10,
    height: "40px",

    textTransform: "none",
    "&:hover": {
      color: "white",
      backgroundColor: "black",
    },
    "&.MuiButton-contained.Mui-disabled": {
      backgroundColor: "black",
    },
  },
  ib: {
    padding: "4px",
    color: "white",
    backgroundColor: "black",
    borderRadius: "6px",
    "&:focus": {
      color: "white",
      backgroundColor: "black",
    },
    "&:active": {
      boxShadow: "none",
      color: "white",
      backgroundColor: "black",
    },
    "&:hover, &.MuiIconButton": {
      background: "#374151 !important",
    },
  },
  flex: {
    display: "flex",
  },
  label: {
    fontStyle: "normal",
    fontWeight: "500px",
    fontSize: "14px",
    lineHeight: "20px",
    width: "140px",
    height: "20px",
  },
  checked: {
    backgroundColor: "black",
    border: "none",
    color: "white",
    opacity: 1,
  },
  thumb: {
    color: "white",
    position: "absolute",
    width: "16px",
    height: "16px",
  },
  // ini untuk unchecked
  track: {
    backgroundColor: "#9c9c9c",
    opacity: 1,
  },
  title: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "28px",
  },
  wrapperAddSociety: {
    border: "1px solid #9AA2B1",
    padding: "16px",
    borderRadius: "8px",
    marginTop: "16px",
  },
  subTitle: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#6e6e6e",
  },
  colorSecondary: {
    "&.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "black",
      opacity: 1,
    },
    "&.Mui-checked": {
      color: "white",
      backgroundColor: "black",
    },
  },
  colorPrimary: {
    "&.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "cyan",
      opacity: 1,
    },
    "&.Mui-checked": {
      color: "white",
      backgroundColor: "yellow",
    },
  },
  buttonAddSociety: {
    display: "flex",
    alignItems: "center",
    color: "#1890ff",
    cursor: "pointer",
  },
  textAdd: {
    color: "white",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "20px",
  },
  autoCompleteDisable: {
    backdrop: "grey",
  },
  colorPicker: {
    width: "400px !important",
    "& .photoshop-picker": {
      width: "200px !important",
    },
  },
  autoComplete: {
    "& .MuiOutlinedInput-root": {
      padding: "0px",
      height: "44px",
    },
    "& .MuiOutlinedInput-root input.MuiInputBase-input.MuiOutlinedInput-input": {
      paddingLeft: "14px",
    },
  },
  font24: {
    fontSize: "24px",
    fontWeight: "700",
    lineHeight: "32px",
  },
  textStyle: {
    marginTop: "5px",
    color: "#8f8f8f",
    fontSize: "14px",
  },
  card: {
    border: "1px solid #9AA2B1",
    borderRadius: "6px",
    boxShadow: "none",
  },
  mt5: {
    marginTop: "5px",
  },
  mtMin5: {
    marginTop: "-5px",
  },
  mt10: {
    marginTop: "10px",
  },
  mt15: {
    marginTop: "15px",
  },
  mt20: {
    marginTop: "20px",
  },
  mtMin10: {
    marginTop: "-10px",
  },
  mb5: {
    marginBottom: "5px",
  },
  mbMin5: {
    marginBottom: "-5px",
  },
  mb10: {
    marginBottom: "10px",
  },
  mbMin10: {
    marginBottom: "-10px",
  },
  mb15: {
    marginBottom: "15px",
  },
  mr5: {
    marginRight: "5px",
  },
  mr10: {
    marginRight: "10px",
  },
  mr15: {
    marginRight: "15px",
  },
  ml5: {
    marginLeft: "5px",
  },
  ml10: {
    marginLeft: "10px",
  },
  ml15: {
    marginLeft: "15px",
  },
  between: {
    justifyContent: "space-between",
  },
  center: {
    justifyContent: "center",
  },
  color687083: {
    color: "#687083",
  },
  outline: {
    width: "100%",
    marginRight: "20px",
    height: "44px",
    border: "1px solid #D1D5DC",
    borderRadius: "6px",
  },
  pointer: {
    cursor: "pointer",
  },
  paper: {
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    display: "flex",
    flexDirection: "column",
    background: "white",
    position: "absolute",
    "& > *": {
      margin: theme.spacing(1),
      width: theme.spacing(16),
      height: theme.spacing(16),
    },
    zIndex: "1000",
  },
  containerForm: {
    width: "300px",
    minHeight: "100px",
    maxHeight: "300px",
    marginTop: "40px",
    borderTop: "1px solid #E4E7EB",
    overflow: "scroll",
  },

  font18: {
    fontSize: "18px",
    lineHeight: "28px",
  },
  font14: {
    fontSize: "14px",
    lineHeight: "20px",
  },
  container: {
    border: "1px solid #9AA2B1",
    padding: "16px",
    borderRadius: "8px",
    marginTop: "16px",
  },
  colorRed: {
    color: "red",
  },
  displayNone: {
    display: "none",
  },
  height38: {
    height: "38px",
  },
  mt100: {
    marginTop: "100px",
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
  },
  width300: {
    width: "300px",
  },
  flexCenter: {
    display: "flex",
    justifyContent: "center",
    marginTop: "10px",
  },
  flexBetween: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "8px",
  },
  displayBlock: {
    display: "block",
    width: "415px",
  },
  gap4: {
    display: "flex",
    justifyContent: "right",
    paddingRight: "24px",
  },
}));

const mockListStatusMember = [
  {
    title: "Member",
    value: true,
    id: 0,
  },
  {
    title: "Non-Member",
    value: false,
    id: 1,
  },
];

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const initialSociety = {
  territory: "",
  society: [],
};

const EditPublisher = props => {
  const { id } = useParams();
  const [color, setColor] = useState();
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [file, setFile] = useState(null);
  const [loadingPage, setLoadingPage] = useState(false);
  const [imagePreview, setImagePreview] = useState("");
  const [name, setName] = useState();
  const [address, setAddress] = useState();
  const [url, setUrl] = useState();
  const [phone, setPhone] = useState();
  const [email, setEmail] = useState();
  const [checkedVal, setcheckedVal] = useState(false);
  const [listSociety, setListSociety] = useState([]);
  const [openModalCal, setOpenModalCal] = useState(false);
  const [listTerritory, setListTerritory] = useState([]);
  const [IpiNameNumber, setIpiNameNumber] = useState("");
  const [IpiBaseNumber, setIpiBaseNumber] = useState("");
  const [dataDsp, setDataDsp] = useState([]);
  const [selectedVal, setSelectedVal] = useState(false);
  const [valueSociety, setValueSociety] = useState([initialSociety]);
  const [association, setAssociation] = useState(null);
  const [associationList, setAssociationList] = useState([]);
  const [status, setStatus] = useState(null);
  const [country, setCountry] = useState(null);
  const [countryList, setCountryList] = useState([]);
  const userRole = localStorage?.getItem("role");
  const isSociety = userRole === "society";
  const isAssociation = userRole === "association";
  const isAssosateAndIsSociety = isAssociation || isSociety;
  const inputFile = useRef(null);
  let token = localStorage.getItem("token");
  const fontInter = createTheme({
    typography: {
      fontFamily: ["Inter"].join(","),
      h5: {
        fontWeight: 700,
      },
    },
  });
  const theme = createTheme({
    typography: {
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        "Segoe UI",
        "Roboto",
        "Helvetica Neue",
        "Arial",
        "sans-serif",
        "Apple Color Emoji",
        "Segoe UI Emoji",
        "Segoe UI Symbol",
      ].join(","),
    },
  });

  const colorPreview = makeStyles(() => ({
    changeColor: {
      backgroundColor: color,
      padding: "10px",
      width: "48px",
      height: "24px",
    },
  }));

  const colorPrev = colorPreview();
  const addSociety = () =>
    setValueSociety(currentSociety => [...currentSociety, initialSociety]);
  const removeSociety = index =>
    setValueSociety(currentSociety =>
      [...currentSociety].filter((_, key) => index !== key)
    );
  const getCountryList = () => {
    let token = localStorage.getItem("token");
    const url = `${hardBaseUrl}/country/table?page=1&per_page=99&search=&sort=`;

    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .get(url, headers)
      .then(res => {
        setCountryList(res.data.data);
      })
      .catch(err => {
        new Error(err);
        Swal.fire({
          title: "Error",
          text: err?.response?.data?.errors[0]?.message || err?.message || "Something Wrong",
          icon: "error",
          confirmButtonText: "Ok",
        });
      });
  };

  const getAssociationList = () => {
    const url = `${hardBaseUrl}/association/all`;
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios.get(url, headers).then(res => {
      setAssociationList(res.data.data);
    }).catch(err => new Error(err));
  };

  const getListTerritory = async () => {
    const url = `${hardBaseUrl}/admin/territory`;
    const params = {
      page: 1,
      size: -1,
    };
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params,
      });
      setListTerritory(response?.data?.data || []);
    } catch (err) {
      new Error(err);
    }
  };

  const getPublisherDetail = () => {
    const url = `${hardBaseUrl}/publisher/${id}`;
    const token = localStorage.getItem("token");
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(url, headers)
      .then(res => {
        const {
          is_member,
          association,
          country,
          ipi_base_number,
          ipi_name_number,
          name,
          email,
          theme_color,
          territories,
          subdomain,
          phone,
          profile_image,
          address
        } = res?.data?.data;
        const getSelectedmember = mockListStatusMember.find(
          ({ value }) => value === is_member
        );
        const valueSociety = (territories || [])?.map(
          ({
            id,
            societies,
            territory_code,
            territory_ext_code,
            territory_name,
            territory_type_flag,
          }) => ({
            territory: {
              id,
              territory_code,
              territory_ext_code,
              territory_name,
              territory_type_flag,
            },
            society: societies,
          })
        );
        if (isSociety) {
          setAssociation([association]);
        }
        setName(name);
        setStatus(getSelectedmember);
        setUrl(subdomain);
        setEmail(email);
        setCountry(country);
        setAssociation(association);
        setIpiNameNumber(ipi_name_number);
        setIpiBaseNumber(ipi_base_number);
        setPhone(phone);
        setAddress(address);
        setImagePreview(profile_image || "");
        setColor(theme_color);
        setValueSociety(valueSociety.length ? valueSociety : [initialSociety]);
      })
      .catch(err => {
        new Error(err);
        Swal.fire({
          title: "Error",
          text:
            err?.response?.data?.errors[0]?.message ||
            err?.message ||
            "Something Wrong",
          icon: "error",
          confirmButtonText: "Ok",
        });
      });
  };
  const getlistSociety = async () => {
    const url = `${hardBaseUrl}/admin/society/`;
    const params = {
      page: 1,
      size: -1,
    };
    const filteredParams = Object.fromEntries(
      Object.entries(params).filter(([, value]) => value)
    );
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: filteredParams,
      });
      setListSociety(response?.data?.data || []);
    } catch (err) {
      new Error(err);
      Swal.fire({
        title: "Error",
        text:
          err?.response?.data?.errors[0]?.message ||
          err?.message ||
          "Something Wrong",
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  };
  useEffect(() => {
    getCountryList();
    if (!isAssosateAndIsSociety) {
      getlistSociety();
      getListTerritory();
      getAssociationList();
    }
    getPublisherDetail();
  }, []);

  const onChange = e => {
    const name = e.target.name;
    const value = e.target.value;

    if (name === "name") {
      setName(value);
    } else if (name === "address") {
      setAddress(value);
    } else if (name === "url") {
      setUrl(value);
    } else if (name === "phone") {
      setPhone(value);
    } else if (name === "email") {
      setEmail(value);
    } else if (name === "IpiBaseNumber") {
      setIpiBaseNumber(value);
    } else if (name === "IpiNameNumber") {
      setIpiNameNumber(value);
    }
  };
  const getBase64 = file => {
    if (file) {
      return new Promise(resolve => {
        let baseURL = "";
        // Make new FileReader
        let reader = new FileReader();

        // Convert the file to base64 text
        reader.readAsDataURL(file);

        // on reader load somthing...
        reader.onload = () => {
          // Make a fileInfo Object
          baseURL = reader.result;
          resolve(baseURL);
        };
        reader.onerror = error => {
          console.error("Error converting file to Base64:", error);
        };
      });
    }
  };

  const onChangeFile = async e => {
    const data = e.target.files[0];
    if (data) {
      const tesRes = await getBase64(data);
      setImagePreview(tesRes);
      setFile(data);
    }
  };
  const onSubmit = e => {
    e.preventDefault();

    // Prepare FormData
    const formData = new FormData();
    formData.append("name", name || "");
    formData.append("address", address || "");
    formData.append("subdomain", url || "");
    formData.append("phone", phone || "");
    formData.append("is_member", status.value || "");
    formData.append("email", email || "");
    formData.append("theme_color", color || "");
    formData.append("ipi_base_number", IpiBaseNumber || "");
    formData.append("ipi_name_number", IpiNameNumber || "");
    formData.append("association_id", association?.association_id || "");
    formData.append("country_id", country?.id || "");
    valueSociety.forEach(({ territory, society }) => {
      formData.append(
        "publisher_affiliations",
        JSON.stringify({
          territory_id: territory?.id,
          society_id: society?.map(({ id }) => id) || [],
        })
      );
    });

    if (file) {
      formData.append("image_logo", file || "");
    }
    dataDsp.forEach(item => {
      formData.append("dsp_ids", item.dsp_id || "");
      formData.append("is_active_flags", item.is_active_flag || "");
    });

    // API request
    const urlBase = `${hardBaseUrl}/publisher/${id}`;
    const token = localStorage.getItem("token");
    const headers = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    };

    setLoadingPage(true);

    axios
      .put(urlBase, formData, headers)
      .then(res => {
        if (
          res.data.message === "success" &&
          res.data.meta.http_status === 200
        ) {
          Swal.fire({
            title: "Success.",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
          }).then(result => {
            if (result.isConfirmed) {
              props.history.push("/admin/parameter/publisher");
            }
          });
        }
      })
      .catch(err => {
        new Error(err);
        Swal.fire({
          title: "Oops…",
          icon: "error",
          text: err.response.data.errors[0]?.message || "An error occurred.",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        });
      })
      .finally(() => {
        setLoadingPage(false);
      });
  };

  const handleChangePhotoButton = e => {
    e.preventDefault();
    inputFile.current.click();
  };

  useEffect(() => {
    const getSwitchFromDb = () => {
      const urlBase = `${hardBaseUrl}/publishers/list/${id}/dsps`;
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      axios
        .get(urlBase, { headers })
        .then(res => {
          if (
            res.data.message === "success" &&
            res.data.meta.http_status === 200
          ) {
            setDataDsp(res.data.data);
            let theCkecked = res.data.data;
            let testChecked = true;
            theCkecked.forEach(item => {
              if (item.is_active_flag === false) {
                testChecked = false;
              }
            });
            setcheckedVal(testChecked);
          }
        })
        .catch((err) => {
          new Error(err);
          Swal.fire({
            title: "Error.",
            icon: "error",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
          });
        });
    };
    getSwitchFromDb();
  }, []);

  // checkedVal
  const handleChecked = event => {
    let newVal = false;
    if (event.target.value === "true") {
      newVal = false;
    } else {
      newVal = true;
    }
    setcheckedVal(newVal);
    let itemsDsp = dataDsp;
    let newDsps = itemsDsp.map(item => {
      let updateItem = {
        ...item,
        is_active_flag: true,
      };
      return updateItem;
    });
    setDataDsp(newDsps);
  };
  const handleCheckedInside = e => {
    let oldVal = selectedVal;
    setSelectedVal(!oldVal);

    let items = dataDsp;
    let newSelVal = e.target.checked;
    items[e.target.id].is_active_flag = newSelVal;

    setDataDsp(items);
  };
  const onClickApplyDate = () => {
    setOpenModalCal(false);
  };
  const onClickSettingDsp = () => {
    setOpenModalCal(true);
  };

  const classes = useStyles();
  const TextInputRequired = ({ text, className, required }) => {
    const classes = useStyles();
    const theme = createTheme({
      typography: {
        fontFamily: ["Helvetica Neue"].join(","),
      },
    });
    return (
      <ThemeProvider theme={theme}>
        <Box className={classes.flex}>
          <Typography className={`${className} ${classes.flex}`}>
            {text}
            {required && (
              <Typography className={classes.required}>*</Typography>
            )}
          </Typography>
        </Box>
      </ThemeProvider>
    );
  };
  return (
    <Page className={classes.root} title="Edit Publisher">
      <Backdrop className={classes.backdrop} open={loadingPage}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container maxWidth={false}>
        <Grid
          alignItems="flex-end"
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <ThemeProvider theme={theme}>
              <Typography className={classes.font24}>Edit Publisher</Typography>
            </ThemeProvider>
          </Grid>
          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <a onClick={() => history.push("/admin/parameter/publisher")}>Master</a>
              <a onClick={() => history.push("/admin/parameter/publisher")}>Publisher</a>
              <Typography>Edit Publisher</Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />

        <form onSubmit={onSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <ThemeProvider theme={fontInter}>
                <Typography variant="h5" className={classes.font18}>
                  DSP Information
                </Typography>
              </ThemeProvider>
              <div className={classes.container}>
                <Grid container>
                  <Grid item xs={12}>
                    <TextInputRequired text="Profile Publisher Name" required />
                    <TextField
                      className={classes.myTextField}
                      fullWidth
                      onChange={e => onChange(e)}
                      id={"name"}
                      name="name"
                      type="text"
                      variant="outlined"
                      value={name}
                      InputProps={{
                        classes: { input: classes.inputFields },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextInputRequired
                      text="Status"
                      className={classes.formRequired}
                    />
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                      fullWidth={true}
                    >
                      <Autocomplete
                        onChange={(_, newValue) => setStatus(newValue)}
                        value={status}
                        options={mockListStatusMember}
                        getOptionLabel={option => option?.title}
                        className={classes.autoComplete}
                        getOptionSelected={(option, value) =>
                          option?.id === value
                        }
                        renderInput={params => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Status"
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextInputRequired
                      text="URL"
                      className={classes.formRequired}
                    />
                    <TextField
                      fullWidth
                      onChange={onChange}
                      id="url"
                      name="url"
                      type="text"
                      variant="outlined"
                      value={url}
                      InputProps={{
                        classes: { input: classes.inputFieldsUrl },
                        endAdornment: (
                          <InputAdornment position="end">
                            .caris.id
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextInputRequired
                      text="Email"
                      className={classes.formRequired}
                      required
                    />
                    <TextField
                      fullWidth
                      onChange={onChange}
                      value={email}
                      id={"email"}
                      name="email"
                      type="text"
                      variant="outlined"
                      InputProps={{
                        classes: { input: classes.inputFields },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextInputRequired
                      text="Country"
                      className={classes.formRequired}
                      required
                    />
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                      fullWidth={true}
                    >
                      <Autocomplete
                        onChange={(_, newValue) => {
                          setCountry(newValue);
                        }}
                        value={country}
                        options={countryList}
                        getOptionLabel={option => option?.country_name}
                        className={classes.autoComplete}
                        getOptionSelected={(option, value) =>
                          option?.id === value
                        }
                        renderInput={params => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Country"
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>

                  {!isAssociation && (
                    <Grid item xs={12}>
                      <ThemeProvider theme={fontInter}>
                        <Typography className={classes.formRequired}>
                          Association
                        </Typography>
                      </ThemeProvider>

                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        fullWidth={true}
                      >
                        <Autocomplete
                          onChange={(_, newValue) => {
                            setAssociation(newValue);
                          }}
                          disabled={isSociety}
                          options={associationList}
                          getOptionLabel={option => option?.name}
                          className={
                            isSociety
                              ? classes.autoCompleteDisable
                              : classes.autoComplete
                          }
                          renderInput={params => (
                            <TextField
                              {...params}
                              variant="outlined"
                              placeholder="Search Association"
                            />
                          )}
                          value={association}
                        />
                      </FormControl>
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <TextInputRequired
                      text="IPI Name Number"
                      className={classes.formRequired}
                      required
                    />
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                      fullWidth={true}
                    >
                      <TextField
                        variant="outlined"
                        placeholder="IPI Name Number"
                        name="IpiNameNumber"
                        value={IpiNameNumber}
                        onChange={onChange}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <ThemeProvider theme={fontInter}>
                      <Typography className={(classes.font14, classes.mt15)}>
                        IPI Base Number
                      </Typography>
                    </ThemeProvider>

                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                      fullWidth={true}
                    >
                      <TextField
                        variant="outlined"
                        placeholder="IPI Base Number"
                        name="IpiBaseNumber"
                        onChange={onChange}
                        value={IpiBaseNumber}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <TextInputRequired
                      text="Phone Number"
                      className={classes.formRequired}
                      required
                    />
                    <TextField
                      className={classes.myTextField}
                      fullWidth
                      onChange={onChange}
                      id="phone"
                      name="phone"
                      value={phone}
                      type="text"
                      variant="outlined"
                      InputProps={{
                        classes: { input: classes.inputFieldsTlp },
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.mr15}
                          >
                            +62
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextInputRequired
                      text="Address"
                      className={classes.formRequired}
                      required
                    />
                    <TextField
                      fullWidth
                      onChange={onChange}
                      value={address}
                      id="address"
                      name="address"
                      type="text"
                      variant="outlined"
                      rows={4}
                      multiline
                    />
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item xs={6}>
              <ThemeProvider theme={fontInter}>
                <Typography variant="h5" className={classes.font18}>
                  Appearance
                </Typography>
              </ThemeProvider>
              <div className={classes.container}>
                <Grid container spacing={3}>
                  <Grid item xs={8}>
                    <TextInputRequired
                      text="Publisher Logo"
                      className={classes.label}
                      required
                    />
                    <Box
                      textAlign="center"
                      display="flex"
                      justifyContent="center"
                      border="1px solid #D1D5DC"
                      height="120px"
                      borderRadius="6px"
                      marginRight="20px"
                      marginTop="6px"
                    >
                      {imagePreview ? (
                        <img
                          src={imagePreview}
                          alt="Preview"
                          className={classes.imgPreview}
                        />
                      ) : (
                        <Box
                          alignItems="center"
                          justifyContent="center"
                          display="flex"
                          flexDirection="column"
                        >
                          <PublishIcon />
                          <Typography>Upload Logo</Typography>
                        </Box>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <div className={classes.mt20}>
                      <input
                        onChange={onChangeFile}
                        ref={inputFile}
                        className={classes.displayNone}
                        type="file"
                        name="logo"
                        accept="image/*"
                      />
                      <PrimaryButton
                        onClick={handleChangePhotoButton}
                        label="Change Logo"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={8}>
                    <TextInputRequired
                      text="Theme Color"
                      className={classes.label}
                      required
                    />
                    <Box>
                      <TextField
                        fullWidth={true}
                        name="color"
                        onChange={onChange}
                        value={color}
                        variant="outlined"
                        disabled
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Box className={colorPrev.changeColor} />
                              <Divider
                                className={classes.colorDivider}
                                orientation="vertical"
                              />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <ExpandMore />
                            </InputAdornment>
                          ),
                        }}
                        onClick={() =>
                          setShowColorPicker(
                            showColorPicker => !showColorPicker
                          )
                        }
                      />
                    </Box>
                    {showColorPicker && (
                      <PhotoshopPicker
                        className={classes.colorPicker}
                        color={color}
                        onAccept={() =>
                          setShowColorPicker(
                            showColorPicker => !showColorPicker
                          )
                        }
                        onCancel={() =>
                          setShowColorPicker(
                            showColorPicker => !showColorPicker
                          )
                        }
                        onChange={updateColor => setColor(updateColor.hex)}
                      />
                    )}
                  </Grid>
                </Grid>
              </div>

              <div className={classes.mt10}>
                <span className={classes.font18}>
                  <b>View Transaction DSP Publisher</b>
                </span>
              </div>
              <div className={classes.mt10}>
                <span>Feature to set view DSP Publisher</span>
              </div>

              <Box
                border="1px solid #9AA2B1"
                borderRadius="8px"
                padding="16px"
                margin="20px 0px"
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <FormGroup className={classes.ml10}>
                    <FormControlLabel
                      control={
                        <Switch
                          disableRipple
                          classes={{
                            root: classes.rootSwitch,
                            switchBase: classes.switchBase,
                            thumb: classes.thumb,
                            track: classes.track,
                            checked: classes.checked,
                            colorSecondary: classes.colorSecondary,
                            colorPrimary: classes.colorPrimary,
                          }}
                          value={checkedVal}
                          checked={checkedVal}
                          onChange={handleChecked}
                        />
                      }
                      label="View All DSP Transaction"
                    />
                  </FormGroup>
                  {!checkedVal && (
                    <PrimaryButton
                      onClick={() => onClickSettingDsp()}
                      label="Setting DSP"
                    />
                  )}
                </Box>
              </Box>
              {!isAssosateAndIsSociety && (
                <Box>
                  <ThemeProvider theme={theme}>
                    <Box display="flex" justifyContent="space-between">
                      <Box>
                        <Typography variant="h5" className={classes.title}>
                          Society
                        </Typography>
                        <Typography
                          variant="caption"
                          className={classes.subTitle}
                        >
                          Input your society fee for the data requirement
                        </Typography>
                      </Box>
                      {valueSociety.length < 5 && (
                        <Box
                          className={classes.buttonAddSociety}
                          onClick={addSociety}
                        >
                          + add society
                        </Box>
                      )}
                    </Box>
                  </ThemeProvider>
                  {valueSociety.map(({ territory, society }, key) => {
                    return (
                      <Box className={classes.wrapperAddSociety} key={key}>
                        <Grid container direction="column" spacing={2}>
                          <Grid item>
                            <ThemeProvider theme={fontInter}>
                              <Typography className={classes.textAddSociety}>
                                Territory
                              </Typography>
                            </ThemeProvider>
                            <FormControl
                              variant="outlined"
                              className={classes.formControl}
                              fullWidth
                            >
                              <Autocomplete
                                onChange={(_, newValue) => {
                                  setValueSociety(currentSociety => {
                                    const updatedSociety = currentSociety.map(
                                      (item, index) =>
                                        index === key
                                          ? { ...item, territory: newValue }
                                          : item
                                    );
                                    return updatedSociety;
                                  });
                                }}
                                options={listTerritory}
                                className={classes.autoComplete}
                                getOptionSelected={(option, value) =>
                                  option?.id === value
                                }
                                getOptionLabel={option =>
                                  option?.territory_code || ""
                                }
                                value={territory || ""}
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder="Select Territory"
                                  />
                                )}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item>
                            <ThemeProvider theme={fontInter}>
                              <Typography className={classes.textAddSociety}>
                                Society
                              </Typography>
                            </ThemeProvider>
                            <FormControl
                              variant="outlined"
                              className={classes.formControl}
                              fullWidth={true}
                            >
                              <Autocomplete
                                getOptionSelected={(option, value) =>
                                  option?.id === value?.id
                                }
                                onChange={(_, value) => {
                                  setValueSociety(currentSociety => {
                                    const updatedSociety = currentSociety.map(
                                      (item, index) =>
                                        index === key
                                          ? { ...item, society: value }
                                          : item
                                    );
                                    return updatedSociety;
                                  });
                                }}
                                options={listSociety}
                                limitTags={19}
                                multiple
                                getOptionLabel={option =>
                                  option?.society_name || ""
                                }
                                value={society}
                                renderOption={(option, { selected }) => {
                                  return (
                                    <Box>
                                      <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        checked={selected}
                                        value={option?.society_name}
                                        className={classes.checkboxIcon}
                                      />
                                      {option.society_name}
                                    </Box>
                                  );
                                }}
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder="Select Society"
                                  />
                                )}
                              />
                            </FormControl>
                          </Grid>
                          {valueSociety.length > 1 && (
                            <Grid item md={12}>
                              <Box
                                display="flex"
                                justifyContent="flex-end"
                                onClick={() => removeSociety(key)}
                              >
                                <IconButton classes={{ root: classes.ib }}>
                                  <img
                                    src={deleteIcon}
                                    alt={`delete society ${key}`}
                                  />
                                </IconButton>
                              </Box>
                            </Grid>
                          )}
                        </Grid>
                      </Box>
                    );
                  })}
                </Box>
              )}
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="flex-end" width="100%">
            <Button
              variant="contained"
              type="submit"
              className={classes.button}
            >
              <ThemeProvider theme={fontInter}>
                <Typography className={classes.textAdd}>Save</Typography>
              </ThemeProvider>
            </Button>
          </Box>
        </form>
        <Dialog
          open={openModalCal}
          onClose={() => {
            setOpenModalCal(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <div>
              <span className={classes.font18}>
                <b>View Transaction DSP Publisher</b>
              </span>
            </div>
            <div className={classes.mt10}>
              <span className={classes.color687083}>
                Feature to set view DSP Publisher
              </span>
            </div>
          </DialogTitle>
          <DialogContent>
            <div className={classes.displayBlock}></div>
            <div className={classes.container}>
              {dataDsp.map((customer, index) => (
                <div className={classes.flexBetween} key={index}>
                  <div>{customer.name}</div>
                  <div>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            disableRipple
                            classes={{
                              root: classes.rootSwitch,
                              switchBase: classes.switchBase,
                              thumb: classes.thumb,
                              track: classes.track,
                              checked: classes.checked,
                              colorSecondary: classes.colorSecondary,
                              colorPrimary: classes.colorPrimary,
                            }}
                            value={customer.is_active_flag}
                            checked={customer.is_active_flag}
                            id={`${Number(index)}`}
                            name={`${Number(index)}`}
                            onChange={handleCheckedInside}
                          />
                        }
                        label=""
                      />
                    </FormGroup>
                  </div>
                </div>
              ))}
            </div>
          </DialogContent>
          <DialogActions className={classes.gap4}>
            <PrimaryButton
              onClick={() => {
                setOpenModalCal(false);
              }}
              label="Back"
            />
            <PrimaryButton
              onClick={() => {
                onClickApplyDate();
              }}
              label="Save"
            />
          </DialogActions>
        </Dialog>
      </Container>
    </Page>
  );
};

export default EditPublisher;
